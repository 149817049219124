import { IAxiosError } from '../models/types'

const onError = (error: IAxiosError) => {
  const status = error.response ? error.response.status : null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorResponse: any = error.config

  if (status === 401 && !errorResponse._retry) {
    console.log('error')
  }
  return Promise.reject(error)
}
export default onError
