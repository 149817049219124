import React, { FC, useState } from 'react'
import './DeleteAccounts.scss'
import { onBack } from '../../services/onBack'
import { HowToDelete } from '../../components/DeleteYourAccounts/HowToDelete/HowToDelete'
import { StepsText } from '../../components/DeleteYourAccounts/StepsText/StepsText'
import img2 from '../../assets/images/accout-delete/2.png'
import img3 from '../../assets/images/accout-delete/3.png'
import img4 from '../../assets/images/accout-delete/4.png'
import img2_2 from '../../assets/images/accout-delete/2_2.png'
import img3_2 from '../../assets/images/accout-delete/3_2.png'
import img4_2 from '../../assets/images/accout-delete/4_2.png'
import error from '../../assets/images/accout-delete/Error.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { Buttons } from '../../components/DeleteYourAccounts/Buttons/Buttons'

const step1 = [
  'Go to your <span>Ello Pay</span> section.',
  'Find the option for <span>My Balance</span> or <span>My Earnings</span>.',
  'Follow the instructions to withdraw all funds from your account.',
]

const step2 = [
  'Navigate to the <span>Subscriptions</span> section.',
  'Cancel any active subscriptions associated with your account.',
  'Ensure that all subscriptions are successfully canceled.',
]

export const DeleteAccounts: FC = () => {
  const [firstImg, setFirstImg] = useState('1')
  const [secondImg, setSecondImg] = useState('3')
  const [thirdImg, setThirdImg] = useState('5')
  const navigation = useNavigate()
  const { pathname } = useLocation()

  const onLink = (link: string) => {
    pathname ? navigation(link) : ''
  }

  return (
    <div className="account">
      <button type="button" onClick={onBack} className="account__arrow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none"
        >
          <path
            d="M23.625 13.5L3.375 13.5M3.375 13.5L11.8215 21.375M3.375 13.5L11.8215 5.625"
            stroke="#929298"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <HowToDelete />
      <div className="account__main">
        <div className="account__title-h2">Steps to Delete Your Account</div>
        <div className="account__wrapper">
          <StepsText
            number={1}
            title={'Withdraw Your Leftover Balance'}
            list={step1}
          />
          <div className="buttons">
            <Buttons
              numberBtn={['1', '2']}
              titleBtn={firstImg}
              onFunc={setFirstImg}
            />
            <img
              src={firstImg === '2' ? img2_2 : img2}
              alt=""
              className="steps__img"
            />
          </div>
        </div>
        <div className="account__wrapper">
          {/* <div className="account__inner"> */}
          <StepsText
            number={2}
            title={'Cancel Active Subscriptions'}
            list={step2}
          />
          <div className="account__content-list-wrap">
            <div className="account__content-title">
              For Subscriptions via Apple or Google:
            </div>
            <div className="account__content-title-list">Apple (iOS):</div>
            <ul className="steps__list">
              <li className="steps__item">Go to Settings on your device.</li>
              <li className="steps__item">
                Tap iTunes & App Store, then tap your Apple ID.
              </li>
              <li className="steps__item">Select View Apple ID and sign in.</li>
              <li className="steps__item">
                Tap Subscriptions and cancel the Ello Messenger subscription.
              </li>
            </ul>

            <div className="account__content-title-list">Google (Android):</div>
            <ul className="steps__list">
              <li className="steps__item">Open the Google Play Store.</li>
              <li className="steps__item">
                Tap the menu icon and select Subscriptions.
              </li>
              <li className="steps__item">
                Cancel the Ello Messenger subscription.
              </li>
            </ul>
          </div>
          {/* </div> */}
        </div>
        <div className="account__wrapper">
          <div className="account__content-list-wrap">
            <div className="steps__title-block">
              <div className="steps__number">3</div>
              <div className="steps__title">
                Transfer Ownership or Delete Paid Channels
              </div>
            </div>
            <div className="account__content-title-list">
              Transfer Ownership:
            </div>
            <ul className="steps__list">
              <li className="steps__item">
                Go to the channel&apos;s settings and select the option to
                transfer ownership.
              </li>
              <li className="steps__item">
                Choose a new owner and confirm the transfer.
              </li>
            </ul>

            <div className="account__content-title-list">Delete Channels:</div>
            <ul className="steps__list">
              <li className="steps__item">
                Navigate to the channel&apos;s settings and follow the steps to
                delete it.
              </li>
            </ul>
          </div>
          <div className="buttons">
            <Buttons
              numberBtn={['3', '4']}
              titleBtn={secondImg}
              onFunc={setSecondImg}
            />
            <img
              src={secondImg === '4' ? img3_2 : img3}
              alt=""
              className="steps__img"
            />
          </div>
        </div>

        <div className="account__wrapper">
          <div className="steps__title-block">
            <div className="steps__number">4</div>
            <div className="steps__title">
              Final Steps Before Deleting Your Account
            </div>
          </div>
          <div className="list-inner">
            <ul className="steps__list">
              <div className="not-list">
                Ensure that all the following are cleared:
              </div>
              <li className="steps__item">
                <span>Ello Pay</span> balances
              </li>
              <li className="steps__item">
                <span>Active subscriptions</span>
              </li>
              <li className="steps__item">
                <span>Owned paid channels</span>
              </li>
              <li className="steps__item">
                <span>AI packs</span> (unused AI requests)
              </li>
              <div className="not-list">
                Once all these conditions are met, the account deletion option
                will be available.
              </div>
            </ul>
          </div>
        </div>
        <div className="account__wrapper account__wrapper-grid">
          <div className="steps__title-block">
            <div className="steps__number">5</div>
            <div className="steps__title">Account Deletion Process</div>
          </div>
          <div className="buttons">
            <Buttons
              numberBtn={['5', '6']}
              titleBtn={thirdImg}
              onFunc={setThirdImg}
            />
            <img
              src={thirdImg === '6' ? img4_2 : img4}
              alt=""
              className="steps__img"
            />
          </div>
        </div>
      </div>
      <div className="account__box-last">
        <div className="important-note-wrap">
          <div className="important-note-wrap__title-inner">
            <img src={error} alt="" className="important-note-wrap__img" />
            <div className="important-note-wrap__title">Important Note</div>
          </div>
        </div>
        <p className="important-note-wrap__paragraph">
          Once the account is deleted, all data, including any purchased
          products or services, will be lost and cannot be recovered. We do not
          store any user data after account deletion. Please be certain of your
          decision before proceeding. If you need assistance at any stage, our
          customer support team is available to help.
        </p>
        <p className="important-note-wrap__paragraph">
          For account deletion request please
          <button
            className="important-note-wrap__btn"
            type="button"
            onClick={() => onLink('/support')}
          >
            click here
          </button>
        </p>
        <p className="important-note-wrap__paragraph">
          We hope this guide assists you in smoothly deleting your Ello
          Messenger account. If you ever wish to return, we will be here to
          welcome you back.
        </p>
        <p className="important-text">Best regards,</p>
        <p className="important-text">Ello Team</p>
      </div>
    </div>
  )
}
