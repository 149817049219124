import { FC } from 'react'
import { Link } from 'react-router-dom'
import close from '../../assets/svg-icon/close.svg'
import './Burger.scss'
import classNames from 'classnames'
import { ERoutes } from '../../enum/types'

export const Burger: FC<{
  setOpen: (value: boolean) => void
  open: boolean
}> = ({ setOpen, open }) => {
  return (
    <div
      className={classNames('header__burger burger', {
        active: open,
      })}
    >
      <div className="burger__header">
        {/* <img src={logo} alt='logo' /> */}
        <div></div>
        <img
          aria-hidden={true}
          style={{ cursor: 'pointer' }}
          onClick={() => setOpen(false)}
          src={close}
          alt="icon"
        />
      </div>
      <ul className="burger__list">
        <li className="burger__item">
          {/* <Link
            onClick={() => setOpen(false)}
            to={'/'}
            className="burger__link link-active"
          >
            Home
          </Link> */}
        </li>
        <li className="burger__item">
          <a
            onClick={() => setOpen(false)}
            href="#personal"
            className="burger__link link-active"
          >
            Personal
          </a>
        </li>
        <li className="burger__item">
          <a
            onClick={() => setOpen(false)}
            href="#business"
            className="burger__link link-active"
          >
            Business
          </a>
        </li>
        <li className="burger__item">
          <a
            onClick={() => setOpen(false)}
            href="#ai_text"
            className="burger__link link-active"
          >
            AI Text
          </a>
        </li>
        <li className="burger__item">
          <a
            onClick={() => setOpen(false)}
            href="#ai_image"
            className="burger__link link-active"
          >
            AI Image
          </a>
        </li>
        <li className="burger__item">
          <a
            onClick={() => setOpen(false)}
            href="#channels"
            className="burger__link link-active"
          >
            Channels
          </a>
        </li>
        <li className="burger__item">
          <a
            onClick={() => setOpen(false)}
            href="#groups"
            className="burger__link link-active"
          >
            Groups
          </a>
        </li>
        <li className="burger__item">
          <a
            onClick={() => setOpen(false)}
            href="#courses"
            className="burger__link link-active"
          >
            Courses
          </a>
        </li>
        <li className="burger__item">
          <a
            onClick={() => setOpen(false)}
            href="#feed"
            className="burger__link link-active"
          >
            Feed
          </a>
        </li>
        <li className="burger__item">
          <a
            onClick={() => setOpen(false)}
            href="#analytics"
            className="burger__link link-active"
          >
            Analytics
          </a>
        </li>
        <li className="burger__item">
          <a
            onClick={() => setOpen(false)}
            href="#financials"
            className="burger__link link-active"
          >
            Financials
          </a>
        </li>
        {window.location.href.includes('stage') && (
          <li className="burger__item">
            <Link
              onClick={() => setOpen(false)}
              to={ERoutes.top_channels}
              className="burger__link link-active"
            >
              TOP Channels
            </Link>
          </li>
        )}

        <li className="burger__item">
          <Link to={ERoutes.support} className="burger__link link-active">
            Contact
          </Link>
        </li>
      </ul>
    </div>
  )
}
