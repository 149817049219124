import { FC } from 'react'
import './Footer.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { ERoutes } from '../../enum/types'

export const Footer: FC = () => {
  const { pathname } = useLocation()
  const navigation = useNavigate()

  const onLink = (link: string) => {
    pathname ? navigation(link) : ''
  }

  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <p className="footer__copy support__copy">{`Copyright © ${moment(
          new Date()
        ).format('YYYY')} Ello Messenger Corp. All rights reserved.`}</p>
        <ul className="footer__policy">
          <li className="footer__text">
            <div
              aria-hidden={true}
              onClick={() => onLink(ERoutes.delete_account)}
            >
              Delete Account
            </div>
          </li>
          <li className="footer__text">
            <div aria-hidden={true} onClick={() => onLink('/privacy-policy')}>
              Privacy Policy
            </div>
          </li>
          {/* <li className='footer__text text-right'>
            <div onClick={() => onLink('/сookie-policy')}>Cookie Policy</div>
          </li> */}
          <li className="footer__text">
            <div aria-hidden={true} onClick={() => onLink('/terms')}>
              Terms
            </div>
          </li>
          <li className="footer__text">
            <div aria-hidden={true} onClick={() => onLink('/ai-terms')}>
              AI Terms
            </div>
          </li>
          <li className="footer__text text-right">
            <div
              aria-hidden={true}
              style={{ borderRight: 'none' }}
              onClick={() => onLink('/support')}
            >
              Support
            </div>
          </li>
          {/* <li className='footer__text'>
            <Link to={ERoutes.FAQ}>FAQ</Link>
          </li>
          <li className='footer__text text-right'>
            <Link to={ERoutes.manuals}>Manuals</Link>
          </li> */}
        </ul>
      </div>
    </footer>
  )
}
