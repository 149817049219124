export const pathnameList: Array<string> = [
  '/privacy-policy',
  '/terms',
  '/ai-terms',
  '/support',
  '/text-ai-common-prompts',
]

export const pathnameListNotSupport: Array<string> = [
  '/privacy-policy',
  '/terms',
  '/ai-terms',
]
