import React, { FC } from 'react'
import parse from 'html-react-parser'

interface IProps {
  title: string
  number: number
  list: Array<string>
}

export const StepsText: FC<IProps> = ({ title, number, list }) => {
  return (
    <div className="steps">
      <div className="steps__title-block">
        <div className="steps__number">{number}</div>
        <div className="steps__title">{title}</div>
      </div>

      <ul className="steps__list">
        {list.map((item, key) => (
          <li key={key} className="steps__item">
            {parse(item)}
          </li>
        ))}
      </ul>
    </div>
  )
}
