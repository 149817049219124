import { createAsyncThunk } from '@reduxjs/toolkit'
import { isAxiosError } from 'axios'
import { endpoint } from './endpoint'
import { IRequestAdd } from '../models/user'
import requestService from './request-service'

export const getCountries = createAsyncThunk(
  'support/getCountries',
  async (data, thunkApi) => {
    try {
      const response = await requestService.get(endpoint.support.COUNTRIES)
      return response.data
    } catch (error) {
      if (isAxiosError(error)) {
        console.log(error)
        if (error.response?.data) {
          console.log(error)
        }
        return thunkApi.rejectWithValue(error.response?.data.errors[0])
      }
    }
  }
)

export const getCategories = createAsyncThunk(
  'support/getCategories',
  async (data, thunkApi) => {
    try {
      const response = await requestService.post(
        endpoint.support.CATEGORIES,
        {}
      )
      return response.data
    } catch (error) {
      if (isAxiosError(error)) {
        console.log(error)
        if (error.response?.data) {
          console.log(error)
        }
        return thunkApi.rejectWithValue(error.response?.data.errors[0])
      }
    }
  }
)

export const addReport = createAsyncThunk(
  'support/addReport',
  async (payload: IRequestAdd, thunkApi) => {
    try {
      const response = await requestService.post(
        endpoint.support.REPORT_ADD,
        payload.request
      )
      payload.reset('The letter was sent successfully')
      return response.data
    } catch (error) {
      payload.reset('The letter was not sent')

      if (isAxiosError(error)) {
        console.log(error)
        if (error.response?.data) {
          console.log(error)
        }
        return thunkApi.rejectWithValue(error.response?.data.errors[0])
      }
    }
  }
)

export const uploadFile = createAsyncThunk(
  'support/uploadFile',
  async (payload: any, thunkApi) => {
    try {
      const response = await requestService.post(
        endpoint.support.UPLOAD,
        payload
      )
      return response.data.session
    } catch (error) {
      if (isAxiosError(error)) {
        console.log(error)
        if (error.response?.data) {
          console.log(error)
        }
        return thunkApi.rejectWithValue(error.response?.data.errors[0])
      }
    }
  }
)
