import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  addReport,
  getCategories,
  getCountries,
  uploadFile,
} from '../../services/supportService'
import { ICategory, ICountriesResponse } from '../../models/user'
import ISupportState from './supportState'
import { useNavigate } from 'react-router-dom'

const initialState: ISupportState = {
  loading: false,
  countries: [],
  categories: [],
  anchor: '/',
  error: '',
  session: '',
  currentUser: undefined,
}
const supportSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setAnchor: (state, action: PayloadAction<string>) => {
      const { payload } = action
      const appState = state

      appState.anchor = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCountries.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getCountries.fulfilled,
      (state, { payload }: PayloadAction<Array<ICountriesResponse>>) => {
        state.loading = false
        state.countries = payload
        state.error = ''
      }
    )
    builder.addCase(getCountries.rejected, (state) => {
      state.loading = false
      state.countries = []
      state.error = ''
    })
    builder.addCase(getCategories.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getCategories.fulfilled,
      (state, { payload }: PayloadAction<Array<ICategory>>) => {
        state.loading = false
        state.categories = payload
        state.error = ''
      }
    )
    builder.addCase(getCategories.rejected, (state) => {
      state.loading = false
      state.categories = []
      state.error = ''
    })
    builder.addCase(addReport.pending, (state) => {
      state.loading = true
    })
    builder.addCase(addReport.fulfilled, (state) => {
      const navigation = useNavigate()
      navigation('/success')
      state.loading = false
      state.error = ''
    })
    builder.addCase(addReport.rejected, (state) => {
      const navigation = useNavigate()
      navigation('/error')
      state.loading = false
      state.error = ''
    })
    builder.addCase(uploadFile.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      uploadFile.fulfilled,
      (state, { payload }: PayloadAction<string>) => {
        state.loading = false
        state.session = payload
        state.error = ''
      }
    )
    builder.addCase(uploadFile.rejected, (state) => {
      state.loading = false
      state.error = ''
    })
  },
})

export const supportReducer = supportSlice.reducer
export const { setAnchor } = supportSlice.actions
