import axios, { AxiosRequestConfig, Method } from 'axios'

import onError from './onError'

export const API_BASE_URL = process.env.REACT_APP_WEB_API_URL

class RequestService {
  get = (
    url: string,
    params?: object,
    isAuthRequired = true,
    contentType = 'application/json'
  ) => {
    return createRequest('GET', url, null, isAuthRequired, contentType, params)
  }

  post = (
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: any,
    isAuthRequired = true,
    contentType = 'application/json'
  ) => {
    return createRequest('POST', url, body, isAuthRequired, contentType)
  }

  multipart = (
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: any,
    isAuthRequired = true,
    contentType = 'application/json'
  ) => {
    return createRequest('POST', url, body, isAuthRequired, contentType)
  }

  put = (
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: any,
    isAuthRequired = true,
    contentType = 'application/json'
  ) => {
    return createRequest('PUT', url, body, isAuthRequired, contentType)
  }

  delete = (
    url: string,
    isAuthRequired = true,
    contentType = 'application/json'
  ) => {
    return createRequest('DELETE', url, null, isAuthRequired, contentType)
  }
}

const createRequest = (
  method: Method,
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any,
  isAuthRequired: boolean,
  contentType: string,
  params?: object
) => {
  console.log(API_BASE_URL + url)
  const config: AxiosRequestConfig = {
    method: method,
    url: API_BASE_URL + url,
    data: body,
    params,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    headers: setHeader(isAuthRequired, contentType),
  }

  return axios(config)
  // .then((response) => response.data)
  // .catch((error: IAxiosError) => {
  //   store.dispatch(resetAuthState());
  // });
}

const setHeader = (isAuthRequired: boolean, contentType: string): void => {
  if (isAuthRequired) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${
      localStorage.getItem('token') || undefined
    }`
  } else {
    delete axios.defaults.headers.common['Authorization']
  }
  axios.defaults.headers.common['Content-Type'] = contentType
}

axios.interceptors.response.use((response) => response, onError)

export default new RequestService()
