import { FC, useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Burger } from '../Burger/Burger'
import { ERoutes } from '../../enum/types'
import logo from '../../assets/svg-icon/logo.svg'
import burgerSvg from '../../assets/svg-icon/burger.svg'
import classNames from 'classnames'
import './Header.scss'

export const Header: FC = () => {
  const [open, setOpen] = useState<boolean>(false)

  const { pathname, hash, key } = useLocation()

  const [session, setSession] = useState<boolean>(false)
  useEffect(() => {
    setSession(true)
  }, [])

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0)
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView()
        }
      }, 0)
    }
  }, [pathname, hash, key])

  return (
    <header id="header-id" className="header">
      <div className="header__wrap">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>

        <ul className="header__list">
          <li className="header__item">
            <Link to="/" className="header__link link-active">
              Home
            </Link>
          </li>
          <li className="header__item">
            <Link to={'/#personal'} className="header__link link-active">
              Personal
            </Link>
          </li>
          <li className="header__item">
            <Link to="/#business" className="header__link link-active">
              Business
            </Link>
          </li>
          <li className="header__item">
            <Link to="/#ai_text" className="header__link link-active">
              AI Text
            </Link>
          </li>
          <li className="header__item">
            <Link to="/#ai_image" className="header__link link-active">
              AI Image
            </Link>
          </li>
          <li className="header__item">
            <Link to="/#channels" className="header__link link-active">
              Channels
            </Link>
          </li>
          <li className="header__item">
            <Link to="/#groups" className="header__link link-active">
              Groups
            </Link>
          </li>
          <li className="header__item">
            <Link to="/#courses" className="header__link link-active">
              Courses
            </Link>
          </li>
          <li className="header__item">
            <Link to="/#feed" className="header__link link-active">
              Feed
            </Link>
          </li>
          <li className="header__item">
            <Link to="/#analytics" className="header__link link-active">
              Analytics
            </Link>
          </li>
          <li className="header__item">
            <Link to="/#financials" className="header__link link-active">
              Financials
            </Link>
          </li>
          {/* {(window.location.href.includes('stage') ||
            window.location.href.includes('localhost')) && ( */}
          <li className="header__item">
            <Link
              to={ERoutes.top_channels}
              className={classNames('header__link link-active', {
                active: pathname.slice(1) === ERoutes.top_channels,
              })}
            >
              TOP Channels
            </Link>
          </li>
          {/* )} */}
          <li className="header__item">
            <Link
              to={ERoutes.support}
              className={classNames('header__link link-active', {
                active: pathname.slice(1) === ERoutes.support,
              })}
            >
              Contact
            </Link>
          </li>
        </ul>
        <div
          aria-hidden={true}
          onClick={() => setOpen(true)}
          className="header__btn-burger"
        >
          <img src={burgerSvg} alt="" />
        </div>
        {session ? <Burger setOpen={setOpen} open={open} /> : null}
        {/* <div className='header__language-block'>
          <LanSvg />
          <div className='header__language-text'>English</div>
        </div> */}
      </div>
    </header>
  )
}
