import React, { FC, MouseEvent } from 'react'
import apple from '../../../assets/images/accout-delete/apple.png'
import android from '../../../assets/images/accout-delete/android.png'

interface IProps {
  titleBtn: string
  onFunc: (value: string) => void
  numberBtn: Array<string>
}

export const Buttons: FC<IProps> = ({ titleBtn, onFunc, numberBtn }) => {
  return (
    <div className="buttons__wrap">
      <button
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          const target = event.currentTarget as HTMLButtonElement
          onFunc(target.name)
        }}
        type="button"
        name={numberBtn[0]}
        className={`buttons__left ${
          titleBtn === numberBtn[0] ? 'active' : null
        }`}
      >
        <img src={apple} alt="" />
        <span>Apple (iOS)</span>
      </button>
      <button
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          const target = event.currentTarget as HTMLButtonElement
          onFunc(target.name)
        }}
        type="button"
        name={numberBtn[1]}
        className={`buttons__right ${
          titleBtn === numberBtn[1] ? 'active' : null
        }`}
      >
        <img src={android} alt="" />
        <span>Google (Android)</span>
      </button>
    </div>
  )
}
