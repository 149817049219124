export enum LoadingStatus {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  NEVER = 'NEVER',
  SUCCESS = 'SUCCESS',
}

export enum ERoutes {
  //Public routes
  home = '/',
  support = 'support',
  success = 'success',
  error = 'error',
  privacy_policy = '/privacy-policy',
  cookie_policy = 'cookie-policy',
  terms = 'terms',
  ai_terms = 'ai-terms',
  text_ai_common_prompts = 'text-ai-common-prompts',
  FAQ = 'FAQ',
  manuals = 'manuals',
  top_channels = 'top-channels',
  ai_image_prompts = 'ai-image-prompts',
  ai_chat_prompts = 'ai-chat-prompts',
  manuals_page = 'page',
  delete_account = '/delete-account',
}

export enum ESuccess {
  reset = 'reset-success',
  forgot = 'forgot-success',
}
