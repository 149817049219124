import { RouteObject, useRoutes } from 'react-router-dom'
import React, { lazy } from 'react'
const MainPage = lazy(() => import('./pages/MainContent/MainContent'))
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy/PrivacyPolicy'))
const Terms = lazy(() => import('./pages/Terms/Terms'))
const AiTerms = lazy(() => import('./pages/AiTerms/AiTerms'))
const NotFound404 = lazy(() => import('./pages/NotFound404/NotFound404'))
const Error500 = lazy(() => import('./pages/Error500/Error500'))
const Success = lazy(() => import('./pages/Success/Success'))
const ForDefiningPage = lazy(() => import('./pages/Support/ForDefiningPage'))
const TextAiCommonPrompts = lazy(
  () => import('./pages/TextAiCommonPrompts/TextAiCommonPrompts')
)
const AiChatPrompts = lazy(() => import('./pages/AiChatPrompts/AiChatPrompts'))
const AiImagePrompts = lazy(
  () => import('./pages/AiImagePrompts/AiImagePrompts')
)
const TopChannels = lazy(() => import('./pages/TopChannels/TopChannels'))

import App from './App'
import { ERoutes } from './enum/types'
import { DeleteAccounts } from './pages/DeleteAccounts/DeleteAccounts'

const routes: RouteObject[] = [
  {
    element: <App />,
    path: '/',
    children: [
      {
        path: ERoutes.privacy_policy,
        element: <PrivacyPolicy />,
      },
      {
        path: ERoutes.top_channels,
        element: <TopChannels />,
      },
      {
        path: ERoutes.ai_chat_prompts,
        element: <AiChatPrompts />,
      },
      {
        path: ERoutes.ai_image_prompts,
        element: <AiImagePrompts />,
      },
      {
        path: ERoutes.terms,
        element: <Terms />,
      },

      {
        path: ERoutes.ai_terms,
        element: <AiTerms />,
      },
      {
        path: ERoutes.error,
        element: <Error500 />,
      },
      {
        path: ERoutes.success,
        element: <Success />,
      },
      {
        path: ERoutes.support,
        element: <ForDefiningPage />,
      },
      {
        path: ERoutes.ai_terms,
        element: <AiTerms />,
      },
      {
        path: ERoutes.text_ai_common_prompts,
        element: <TextAiCommonPrompts />,
      },
      {
        path: ERoutes.delete_account,
        element: <DeleteAccounts />,
      },
      {
        path: '*',
        element: <NotFound404 />,
      },
      {
        path: '/',
        element: <MainPage />,
      },
    ],
  },
]

export { routes }
export default function Router() {
  return useRoutes(routes)
}
