import React, { FC } from 'react'
import img1 from '../../../assets/images/accout-delete/1.svg'

export const HowToDelete: FC = () => {
  return (
    <div className="account__box-first">
      <div className="account__box-content">
        <div className="account__title-h1">
          How to Delete Your <br /> <span>Ello Messenger</span> Account
        </div>
        <p className="account__paragraph">
          If you&apos;ve decided to delete your Ello Messenger account, please
          follow the steps outlined below. This process is designed to ensure
          that all relevant actions are taken care of before your account is
          permanently deleted.
        </p>
        <p className="account__paragraph">
          Note that once you delete your account,{' '}
          <span>all user data will be deleted forever</span>, and it cannot be
          recovered.
        </p>
      </div>
      <div>
        <img src={img1} alt="" className="account__img" />
      </div>
    </div>
  )
}
